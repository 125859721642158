import React from 'react'
import PropTypes from 'prop-types'

import { graphql } from 'gatsby'

import { TitleWithLineAndDescription } from '../components/TitleWithLine' 
import { HeaderContainer } from '../components/StyledComponents'

const HeroNobackground = props => {
  if (props.hero) {
    return (
      <HeaderContainer>
        <TitleWithLineAndDescription
          title={props.hero.title}
          description={props.hero.childContentfulHeroSubtitleTextNode.subtitle}
        />
      </HeaderContainer>
    )
  } else {
    return (
      <HeaderContainer>
        <TitleWithLineAndDescription
          title={props.title}
          description={props.description}
        />
      </HeaderContainer>
    )
  }
}

HeroNobackground.propTypes = {
  hero: PropTypes.shape({
    title: PropTypes.string.isRequired,
    childContentfulHeroSubtitleTextNode: PropTypes.shape({
      subtitle: PropTypes.string,
    }).isRequired,
  }).isRequired,
}

export default HeroNobackground

export const heroNoBackgroundFragment = graphql`
  fragment HeroFragment_withNoBackground on ContentfulHero {
    title
    subtitle {
      childMarkdownRemark {
        html
      }
    }
    childContentfulHeroSubtitleTextNode {
      subtitle
    }
  }
` 