import Img from 'gatsby-image'
import styled from 'styled-components'
import posed from 'react-pose'

import { media } from '../utils/media'
import { space } from '../utils/tokens'
// These components are reusable components built using styled

export const AbsoluteTop = styled.div`
  z-index: 1;
  position: absolute;
  right: 1em;
  left: 1em;
  top: 2em;

  ${media.tablet`
    top: 5em;
  `}
`

export const AbsoluteBottom = styled.div`
  z-index: 1;
  position: absolute;
  right: 1em;
  left: 1em;
  bottom: 2em;

  ${media.tablet`
    bottom: 5em;
  `}
`

export const FlexColumn = styled.div `
  display: flex;
  flex-direction: column;
`

export const FlexRow = styled.div`
  display: flex;
`

export const FlexItem = styled.div`
  flex: 1;
  margin: 0 8px;
`

export const Background = styled.div`
  height: inherit;
  background-color: ${props => {
    if (props.dark) {
      return props.theme.colors.base
    } else if (props.gray) {
      return props.theme.colors.lightGray
    } else {
      return '#fff'
    }
  }};
`

export const BgImg = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;

  height: ${props => props.mobileHeight || `100vh`};

  ${media.tablet`
    height: ${props => props.height || `85vh`};
  `}
  

  /* For iphone 5 users */
  @media (max-width: 321px) {
    height: 150vh;
  }

  & > img {
    object-fit: ${props => props.fit || 'cover'} !important;
    object-position: ${props => props.position || '50% 50%'} !important;
  }
  &::before {
    content: '';
    background: ${
      props => props.overlay === "none" ?
      `rgba(0, 0, 0, 0.5)` : `rgba(0, 0, 0, 0.25)`
    };
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
`

export const CenterContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const HeaderContainer = styled.div `
  display: flex;
  justify-content: center;
  align-items: flex-end;

  padding-top: ${space[6]}px;
  padding-left: 2rem;
  padding-right: 2rem;
  
  ${media.desktop `
    padding-top: ${space[5]}px;
  `}
`

export const LineBreak = styled.hr`
  display: block;
  margin-top: 1rem;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border: 3px solid;
  box-sizing: border-box;
  max-width: 150px;
  width: 100%;
  color: ${props => props.theme.colors.primaryBrand};
  margin-bottom: 2rem;
  transform-origin: 50% 50%;

  ${media.tablet`
    max-width: none;
    width: 75px;
  `}
`
export const CoverImg = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
  height: ${props => props.height};
  @media (min-width: ${props => props.theme.responsive.small}) {
    height: ${props => props.height || 'auto'};
  }
  & > img {
    object-fit: ${props => props.fit || 'cover'} !important;
    object-position: ${props => props.position || '50% 50%'} !important;
  }
  &::before {
    content: '';
    opacity: 0.2;
    background: ${props => props.theme.colors.base};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
`

export const CardContent = styled.div`
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  display: flex;
  padding: 0 1rem;
  justify-content: center;
  flex-direction: column;
  box-align: center;

  p {
    overflow-y: scroll;
  }
`

export const FullWidth = styled.section`
  width: 100%;
`

export const Section = styled.section`
  height: ${props => props.fullHeight ? `100vh` : null};
`

export const SectionWithMargin = styled.section`
  margin-top: 3rem;
`

export const FlexWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export const FlexCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const CompanyImg = styled.img`
  max-height: 48px;
  width: auto;
  max-width: 96px;
  margin: 0;
`

export const BrandGroup = styled(posed.div({
  enter: {
    delayChildren: 600,
  },
  initial: {
    staggerDirection: -1,
    transition: {
      delay: 1000,
    },
  }
}))`
  width: 100%;
  border-top: 1px solid ${props => props.theme.colors.lightGray};
`

export const SectionTitleContainer = styled.div`
  padding: 3em 0 2em 0;
  ${media.desktop`
    padding: 4em 0 2em 0;
  `}
`