import React from 'react'
import styled from 'styled-components'

import { LineBreak } from '../components/StyledComponents'

import { media } from '../utils/media'

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
  color: ${props => props.light ? '#fff': props.theme };
  text-align: center;
`

const TitleH2 = styled.h2`
  text-align: center;
  font-size: 2rem;
  margin-bottom: 1rem;
  font-weight: bold;
  color: ${props => props.light ? '#fff' : props.theme };

  ${media.desktop`
    font-size: 2.5rem;
  `}
`
const TitleH3 = styled.h3`
  text-align: center;
  font-size: 2rem;
  margin-bottom: 1rem;
  font-weight: bold;
  color: ${props => props.light ? '#fff' : props.theme };

  ${media.desktop`
    font-size: 2.5rem;
  `}
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const TitleDescription = styled.p`
  text-align: center;
  color: ${ props => props.theme.colors.base}b1;
  font-size: 24px;
  font-weight: 400;
  max-width: 600px;
  margin: 0 auto 3rem;
`

export const TitleWithLine = props => (
  <Container
    as="header"
  >
    <Title className={props.className} light={props.light}>{props.children}</Title>
    <LineBreak />
  </Container>
)

export const TitleWithLineH2 = props => (
  <Container>
    <TitleH2 className={props.className} light={props.light}>{props.children}</TitleH2>
    <LineBreak />
  </Container>
)

export const TitleWithLineH3 = props => (
  <Container>
    <TitleH3 className={props.className} light={props.light}>{props.children}</TitleH3>
    <LineBreak />
  </Container>
)

export const TitleWithLineAndDescription = props => (
  <Container>
    <Title light={props.light}>{props.title}</Title>
    <LineBreak />
    <TitleDescription>{props.description}</TitleDescription>
  </Container>
)