import React from 'react'
import styled, { css } from 'styled-components'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout'

import HeroNobackground from '../components/HeroNoBackground'

import UploadingIcon from '../assets/icons/uploading.svg'
import UploadingIconTwo from '../assets/icons/uploading-2.svg'

import weTransfer from '../images/wetransfer.png'
import googleDrive from '../images/google-drive.png'

import { media } from '../utils/media'

const Card = styled.div`
  height: 100%;
  width: 100%;
  box-shadow: 0 2px 26px rgba(0,0,0,0.15);
  border: 1px solid #EFEFEF;
  background-color: ${props => props.theme.colors.white};
  border-radius: 10px;
  color: ${props => props.theme.colors.base};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  text-decoration: none;
  padding: 1rem;
`

const CardTitle = styled.h3`
  font-weight: 500;
  text-align: center;
  color: black;
  opacity: 0.7;
  margin-bottom: 1rem;
`


const CardText = styled.span`
  font-weight: 500;
  text-align: center;
  color: black;
  opacity: 0.5;
  font-size: 16px;
`
const UnstyledAnchor = styled.a`
  text-decoration: none;
`

const Img = styled.img`
  width: 128px;
  height: auto;
  pointer-events: none;
`

const FileUploadPage = (props) => {
  return (
    <Layout css={css`
      position: relative;
    `}>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <HeroNobackground
        hero={{
          title: "Upload your file",
          childContentfulHeroSubtitleTextNode: {
            subtitle: "Here are some ways you can send us your files"
          }
        }}
      />
      <div css={css`
          position: absolute;
          bottom: 0;
          left: 0;
          transform: translateX(-25%);
          width: 100%;
          z-index: -1;
          ${media.desktop`
            left: 0;
            top: 20vh;
            transform: translateX(-25%);
            width: 50%;
            padding: 0 8rem;
          `}
        `}>
        <UploadingIcon css={css`
            width: 100%;
            height: auto;
          `} />
      </div>
      <div css={css`
        width: 100%;
        position: absolute;
        right: 0;
        top: 40vh;
        z-index: -1;
        width: 50%;
        ${media.desktop`
          padding: 0 8rem;
        `}
      `}>
      <UploadingIconTwo
        css={css`
          width: 100%;
          height: auto;
      `} />
      </div>
      <section css={css`
        margin: auto;
        display: flex;
        flex-direction: column;
        padding: 1rem;

        ${media.desktop`
          flex-direction: row;
          align-items: center;
          padding-bottom: 8rem;
          position: relative;
        `}

        ${media.hd`
          max-width: 1440px;
          margin: auto;
        `}
      `}>
        <div css={css`
          width: 100%;
        `}>
          <ul css={css`
            z-index: 1;
            font-size: 1.25rem;
            list-style: none;
            > * {
              margin-bottom: 1rem;
            }

            ${media.desktop`
              display: grid;
              grid-template-columns: 1fr 1fr;
              grid-gap: 2rem;
              max-width: 600px;
              margin: auto;
            `}
          `}>
            <li>
              <UnstyledAnchor
                href="https://wetransfer.com/?to=contact@prtwd.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Card>
                  <Img src={weTransfer} alt="wetransfer logo" />
                  <CardTitle>Send through WeTransfer</CardTitle>
                  <CardText>It 's totally free and you can upload up to 2 gigabytes</CardText>
                </Card>
              </UnstyledAnchor>
            </li>
            <li>
              <UnstyledAnchor
                href="https://drive.google.com/drive/u/0/my-drive"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Card>
                  <Img src={googleDrive} alt="wetransfer logo" />
                  <CardTitle>Upload on Google Drive</CardTitle>
                  <CardText>Once uploaded, you can share it to us at contact@prtwd.com</CardText>
                </Card>
              </UnstyledAnchor>
            </li>
          </ul>
        </div>
      </section>
    </Layout>
  )
}

export default FileUploadPage